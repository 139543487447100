<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Mes a consultar"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            type="month"
            no-title
            scrollable
            color="primary"
            :max="new Date().toISOString().substr(0, 7)"
          >
            <v-spacer></v-spacer>
            <v-btn text @click="menu = false"> Cancel</v-btn>
            <v-btn text @click="$refs.menu.save(date)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <!--      <v-col cols="12" md="5">-->
      <!--        <v-select-->
      <!--          :items="clientes"-->
      <!--          v-model="selectedClients"-->
      <!--          multiple-->
      <!--          label="Cliente"-->
      <!--        >-->
      <!--          <template v-slot:selection="{ item, index }">-->
      <!--            <span v-if="index === 0">{{ item }}</span>-->
      <!--            <span v-if="index === 1" class="grey&#45;&#45;text caption">-->
      <!--              (+{{ selectedClients.length - 1 }})-->
      <!--            </span>-->
      <!--          </template>-->
      <!--          <template v-slot:prepend-item>-->
      <!--            <v-list-item ripple @click="toggle">-->
      <!--              <v-list-item-action>-->
      <!--                <v-icon-->
      <!--                  :color="selectedClients.length > 0 ? 'indigo darken-4' : ''"-->
      <!--                >-->
      <!--                  {{ icon }}-->
      <!--                </v-icon>-->
      <!--              </v-list-item-action>-->
      <!--              <v-list-item-content>-->
      <!--                <v-list-item-title> Todos</v-list-item-title>-->
      <!--              </v-list-item-content>-->
      <!--            </v-list-item>-->
      <!--            <v-divider class="mt-2"></v-divider>-->
      <!--          </template>-->
      <!--        </v-select>-->
      <!--      </v-col>-->
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card id="ventas-producto">
          <v-toolbar flat>
            <v-toolbar-title> Producción Diaria</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-divider class="mb-3 primary" />
          <v-card-text class="pa-3" style="height: auto">
            <v-chart
              ref="chartDiaria"
              theme="walden"
              class="ma-auto"
              style="width: 100%"
              :options="chartDiaria"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-card id="ventas-acumuladas">
          <v-toolbar flat>
            <v-toolbar-title> Producción Acumulada</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-divider class="mb-3 primary" />
          <v-card-text class="pa-3" style="height: auto">
            <v-chart
              ref="chartAcumulada"
              theme="walden"
              class="ma-auto"
              style="width: 100%"
              :options="chartAcumulada"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import {TweenMax} from "gsap";
import ECharts from "vue-echarts";
// import ECharts modules manually to reduce bundle size
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/markLine";
import "echarts/lib/component/legend";
import theme from "@/assets/branding/chartTheme.json";

ECharts.registerTheme("walden", theme);

export default {
  name: "Diaria",
  components: {
    "v-chart": ECharts,
  },
  data: (vm) => ({
    clientes: [
      "AT HOME",
      "AMERICAN FURNITURE",
      "CHEYENNE",
      "STEVE SILVER COMPANY",
      "FURNITURE OUTLETS USA",
    ],
    selectedClients: [
      "AT HOME",
      "AMERICAN FURNITURE",
      "CHEYENNE",
      "STEVE SILVER COMPANY",
      "FURNITURE OUTLETS USA",
    ],
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    // facturado: 0,
    // tweenPorFacturar: 0,
    // porFacturar: 0,
    // tweenPorFaturar: 0,
    // neto: 0,
    // tweenNeto: 0,
    chartAcumulada: {
      tooltip: {
        confine: true,
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: function (params) {
          let output = "<b>" + params[0].name + "</b><br/>";
          for (let i = 0; i < params.length; i++) {
            output +=
              params[i].marker +
              params[i].seriesName +
              ": " +
              vm.$options.filters.toCurrency(params[i].value); // : every 2nth

            if (i !== params.length - 1) {
              // Append a <br/> tag if not last in loop
              output += "<br/>";
            }
          }
          return output;
        },
      },
      legend: {
        data: ["Producción", "Contribución", "Mano de obra"],
      },
      // grid: {
      //   left: '3%',
      //   right: '4%',
      //   bottom: '3%',
      //   containLabel: true
      // },
      xAxis: {
        type: "category",
        data: [],
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "${value}",
        },
      },
      series: [
        {
          name: "Producción",
          type: "line",
          // markLine: {
          //   data: [
          //     {
          //       label: {
          //         show: true,
          //         formatter: function (d) {
          //           return vm.$options.filters.toCurrency(d.value);
          //         },
          //       },
          //       name: "Meta",
          //       yAxis: 1257109,
          //     },
          //   ],
          //   silent: true,
          // },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Contribución",
          type: "line",
          // markLine: {
          //   data: [
          //     {
          //       label: {
          //         show: true,
          //         formatter: function (d) {
          //           return vm.$options.filters.toCurrency(d.value);
          //         },
          //       },
          //       name: "Meta",
          //       yAxis: 400000,
          //     },
          //   ],
          //   silent: true,
          // },
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Mano de obra",
          type: "line",
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
    },
    chartDiaria: {
      tooltip: {
        confine: true,
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: function (params) {
          let output = "<b>" + params[0].name + "</b><br/>";
          for (let i = 0; i < params.length; i++) {
            output +=
              params[i].marker +
              params[i].seriesName +
              ": " +
              vm.$options.filters.toCurrency(params[i].value); // : every 2nth

            if (i !== params.length - 1) {
              // Append a <br/> tag if not last in loop
              output += "<br/>";
            }
          }
          return output;
        },
      },
      legend: {
        data: ["Producción", "Contribución", "Mano de obra"],
      },
      xAxis: {
        type: "category",
        data: [],
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: "${value}",
        },
      },
      series: [
        {
          name: "Producción",
          barGap: "-100%",
          type: "bar",
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Contribución",
          type: "bar",
          barGap: "-100%",
          emphasis: {
            focus: "series",
          },
          data: [],
        },
        {
          name: "Mano de obra",
          type: "bar",
          barGap: "-100%",
          emphasis: {
            focus: "series",
          },
          data: [],
        },
      ],
    },
  }),
  computed: {
    allClients() {
      return this.selectedClients.length === this.clientes.length;
    },
    algunosClientes() {
      return this.selectedClients.length > 0 && !this.allClients;
    },
    icon() {
      if (this.allClients) return "mdi-close-box";
      if (this.algunosClientes) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    date: function () {
      this.getProductionReport();
    },
    // facturado(newValue) {
    //   TweenMax.to(this.$data, 0.7, {tweenPorFacturar: newValue})
    // },
    // porFacturar(newValue) {
    //   TweenMax.to(this.$data, 0.7, {tweenPorFaturar: newValue})
    // },
    // neto(newValue) {
    //   TweenMax.to(this.$data, 0.7, {tweenNeto: newValue})
    // },
  },
  mounted() {
    this.getProductionReport();
    // this.facturado = 709057
    // this.porFacturar = 549221
    // this.neto = 1257109
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.allClients) {
          this.selectedClients = [];
        } else {
          this.selectedClients = this.clientes.slice();
        }
      });
    },
    getProductionReport() {
      // this.clientsLoading = true;
      this.$refs.chartDiaria.chart.showLoading("default", {
        text: "Cargando Datos",
      });
      this.$refs.chartAcumulada.chart.showLoading("default", {
        text: "Cargando Datos",
      });
      this.$http
        .post("daily_production/getProductionReport", {
          date: this.date,
        })
        .then((res) => {
          // this.clientes = res.data.data;
          this.$nextTick(() => {
            this.$refs.chartDiaria.chart.setOption({
              xAxis: {
                type: "category",
                data: res.data.days,
              },
              series: [
                {
                  name: "Producción",
                  data: res.data.production,
                },
                {
                  name: "Contribución",
                  data: res.data.contribution,
                },
                {
                  name: "Mano de obra",
                  data: res.data.workforce,
                },
              ],
            });
            this.$refs.chartAcumulada.chart.setOption({
              xAxis: {
                type: "category",
                data: res.data.days,
              },
              series: [
                {
                  name: "Producción",
                  data: res.data.productionC,
                },
                {
                  name: "Contribución",
                  data: res.data.contributionC,
                },
                {
                  name: "Mano de obra",
                  data: res.data.workforceC,
                },
              ],
            });
          });
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.msg) {
              this.$toast.error(error.response.data.msg);
            } else {
              this.$toast.error("Ocurrio un error inesperado.");
            }
          } else {
            this.$toast.error("Ocurrio un error inesperado.");
          }
        })
        .finally(() => {
          this.$refs.chartDiaria.chart.hideLoading();
          this.$refs.chartAcumulada.chart.hideLoading();
          // this.clientsLoading = false;
          // this.$store.dispatch("working", false);
        });
    },
  },
};
</script>

<style scoped></style>
